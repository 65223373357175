// src/Components/Navbar.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the mobile menu
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-black text-white fixed top-0 left-0 w-full shadow-lg z-[5000]">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center space-x-3">
          <Link to="/" className="flex items-center">
            {/* <svg className="w-12 h-12 text-white hover:text-gray-300 transition-transform duration-300 transform hover:scale-110" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 7h14M3 11h14M3 15h14M10 5h7M10 19h7M14 7h7M14 11h7M14 15h7" />
            </svg> */}
            <span className="md:ml-6 text-3xl font-bold">Usama</span>
          </Link>
        </div>
        
        {/* Navigation Links */}
        <div className="space-x-6 hidden md:flex">
          <Link to="/" className="px-4 py-2 rounded transition-all duration-300 hover:bg-white hover:text-black hover:shadow-lg">Home</Link>
          <Link to="/contact" className="px-4 py-2 rounded transition-all duration-300 hover:bg-white hover:text-black hover:shadow-lg">Contact</Link>
          <Link to="/about" className="px-4 py-2 rounded transition-all duration-300 hover:bg-white hover:text-black hover:shadow-lg">About</Link>
        </div>
        
        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div ref={mobileMenuRef} className={`md:hidden fixed top-0 left-0 w-full bg-black text-white ${isMobileMenuOpen ? 'block' : 'hidden'}`} style={{ zIndex: 5000 }}>
        <div className="flex flex-col items-center py-4">
          <Link to="/" className="py-2 px-4 rounded transition-all duration-300 hover:bg-white hover:text-black hover:shadow-lg" onClick={toggleMobileMenu}>Home</Link>
          <Link to="/contact" className="py-2 px-4 rounded transition-all duration-300 hover:bg-white hover:text-black hover:shadow-lg" onClick={toggleMobileMenu}>Contact</Link>
          <Link to="/about" className="py-2 px-4 rounded transition-all duration-300 hover:bg-white hover:text-black hover:shadow-lg" onClick={toggleMobileMenu}>About</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
