import React from 'react';
import './About.css'; // Import the CSS for the component

const About = () => {
   

  return (
    <section className="about-me" id="about-me">
      <div className="container">
        <div className="about-me-container !items-center  !justify-center">
          <div className="about-me-title mb-20 pt-20 text-center">
            Rao Muhammad Usama
          </div>

          <div className="about-me-flex-container">
            <div className="about-me-image">
              <div className="back-div"></div>
              <div className="black-image">
                <img src="https://raw.githubusercontent.com/Smit-Prajapati/prajapatismit/main/images/black.jpg" alt="black" />
              </div>
              <div className="main-image">
                <img src="https://raw.githubusercontent.com/Smit-Prajapati/prajapatismit/main/images/smit.jpg" alt="smit" />
              </div>
            </div>
            <div className="about-me-content flex flex-col !items-center  !justify-center space-y-6">
  <div className="logo">
    <img 
      src="https://raw.githubusercontent.com/Smit-Prajapati/prajapatismit/20391be8bf1ed24ef0e5da066bf68a5f6ee78fa1/images/logo.svg" 
      alt="smit" 
    />
  </div>
  <div className="text text-center px-10">
    An ambitious Front-end Developer and designer who takes great pride in the presentation and quality of work.
    <br /><br />
    Smit is someone who can design and create simple, beautiful and easy to understand things. He is an expert at taking designs into original, exciting and new directions.
  </div>
</div>

          </div>

          <div className="mail-button mail-button2 !items-center  !justify-center">
            <a href="mailto:prajapatismit2906@gmail.com">
              <img src="https://raw.githubusercontent.com/Smit-Prajapati/prajapatismit/20391be8bf1ed24ef0e5da066bf68a5f6ee78fa1/images/mail.svg" alt="mail" />
            </a>
          </div>
        </div>

        <section className="skills-section">
          <div className="skills-title">MY Skills</div>
          <div className="skills-container">
            <div className="skill-item">JavaScript</div>
            <div className="skill-item">React</div>
            <div className="skill-item">CSS</div>
            <div className="skill-item">HTML</div>
            <div className="skill-item">GSAP</div>
          </div>
        </section>

      </div>
    </section>
  );
};

export default About;
