import React, { useState, useEffect } from 'react';
import styles from './Logo.module.css';

const Logo = ({ onAnimationComplete }) => {
  const [overlayText, setOverlayText] = useState('Hi');
  const [slideUpFadeOut, setSlideUpFadeOut] = useState(false);

  useEffect(() => {
    const messages = ['Hi', 'Halo', 'Привет', 'Hallo', 'Assalamo Alaikum'];
    let messageIndex = 0;

    setTimeout(() => {
      const messageInterval = setInterval(() => {
        messageIndex += 1;
        if (messageIndex >= messages.length) {
          messageIndex = -1; // Reset to loop through messages, skipping the initial "Hi"
        }
        setOverlayText(messages[messageIndex]);
      }, 200);

      setTimeout(() => {
        clearInterval(messageInterval);
        setSlideUpFadeOut(true);
        if (onAnimationComplete) {
          onAnimationComplete(); // Notify when animation is complete
        }
      }, 1000); // Clear the interval and start fade-out after 1 second

    }, 1500); // Initial delay for the first message to appear

  }, [onAnimationComplete]);

  return (
    <div>
      <div id="overlay" className={`${styles.overlay} ${slideUpFadeOut ? styles.slideUpFadeOut : ''}`}>
        <h1 id="overlay-text" className={styles.overlayText}>
          <span id={styles.circle}></span>{overlayText}
        </h1>
      </div>
      <div className={styles.content}>
      </div>
    </div>
  );
};

export default Logo;
