import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import TextEffect from './TextEffect'; // Ensure this path is correct
import './AboutMe.css'; // Make sure this path is correct
import { Link } from "react-router-dom";

function AboutMe() {
  const buttonRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const button = buttonRef.current;

    const handleMouseMoveButton = (e) => {
      const rect = button.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const moveX = (x - rect.width / 2) / 5;
      const moveY = (y - rect.height / 2) / 5;

      gsap.to(button, {
        x: moveX,
        y: moveY,
        duration: 0.5,
        ease: 'power2.out',
      });

      const text = button.querySelector('span');
      if (text) {
        gsap.to(text, {
          x: moveX,
          y: moveY,
          duration: 0.5,
          ease: 'power2.out',
        });
      }
    };

    const handleMouseLeaveButton = () => {
      gsap.to(button, {
        x: 0,
        y: 0,
        duration: 0.5,
        ease: 'power2.out',
      });

      const text = button.querySelector('span');
      if (text) {
        gsap.to(text, {
          x: 0,
          y: 0,
          duration: 0.5,
          ease: 'power2.out',
        });
      }
    };

    if (button) {
      button.addEventListener('mousemove', handleMouseMoveButton);
      button.addEventListener('mouseleave', handleMouseLeaveButton);
    }

    return () => {
      if (button) {
        button.removeEventListener('mousemove', handleMouseMoveButton);
        button.removeEventListener('mouseleave', handleMouseLeaveButton);
      }
    };
  }, []);

  useEffect(() => {
    const container = document.querySelector('.floating-elements');

    // Create floating items
    const createFloatingItem = () => {
      const item = document.createElement('div');
      item.className = 'floating-item';
      item.style.width = `${Math.random() * (25 - 5) + 5}px`;
      item.style.height = item.style.width;
      item.style.left = `${Math.random() * 100}vw`;
      item.style.top = `${Math.random() * 100}vh`;
      item.style.zIndex = 0;
      container.appendChild(item);

      gsap.to(item, {
        x: `+=${Math.random() * 200 - 100}px`,
        y: `+=${Math.random() * 200 - 100}px`,
        duration: Math.random() * 10 + 10, // Random duration between 10-20 seconds
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
      });
    };

    for (let i = 0; i < 15; i++) {
      createFloatingItem();
    }
  }, []);

  return (
    <>
  <section ref={containerRef} data-aos="fade-up" className="sm:mx-14 mx-6 flex sm:my-20 p-4 justify-center relative flex-col md:flex-row md:items-start">
    {/* Text Section */}
    <div className="w-full md:w-1/2 text-center md:text-left md:pr-8 flex flex-col items-center md:items-start">
      <h1 className="font-manrope pb-5 text-center text-5xl font-bold leading-[70px] text-black md:text-left md:text-6xl">
        About Me
      </h1>
      <TextEffect text="Schedule A Call So I Can Learn About Your Product And We Can Discuss The Best Way To Help You Meet Your Goals. Another text with different size." fontSize="0.8rem" />
      /About
      <Link to="/About">

      <div ref={buttonRef} className="inline-block mt-4">
        <button className="flex px-6 py-3 sm:py-3 sm:px-10 items-center justify-center rounded-full border-black border-2 text-black transition-colors duration-500 ease-in-out hover:bg-black hover:text-white text-xs sm:text-base">
          <span>Contact Me</span>
        </button>
      </div>
</Link>
    </div>

    {/* Image Section */}
    <div className="w-full md:w-1/2 mt-8 md:mt-0 flex justify-center">
      <img
        src="https://images.pexels.com/photos/458381/pexels-photo-458381.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        alt="Illustration"
        className="h-72 w-full rounded-3xl object-cover transition-transform duration-500 ease-in-out hover:scale-90 cursor-pointer"
      />
    </div>
  </section>
  <div className="floating-elements"></div>
</>

  );
}

export default AboutMe;
