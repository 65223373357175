import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function Footer() {
  const buttonRefs = useRef([React.createRef(), React.createRef(), React.createRef()]);

  useEffect(() => {
    buttonRefs.current.forEach((buttonRef) => {
      const button = buttonRef.current;

      const handleMouseMoveButton = (e) => {
        const rect = button.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const moveX = (x - rect.width / 2) / 5; // Adjust the move factor for smoother movement
        const moveY = (y - rect.height / 2) / 5;

        gsap.to(button, {
          x: moveX,
          y: moveY,
          duration: 0.5, // Faster duration
          ease: 'power2.out',
        });

        const text = button.querySelector('span');
        if (text) {
          gsap.to(text, {
            x: moveX,
            y: moveY,
            duration: 0.5, // Faster duration
            ease: 'power2.out',
          });
        }
      };

      const handleMouseLeaveButton = (e) => {
        gsap.to(button, {
          x: 0,
          y: 0,
          duration: 0.5, // Faster duration
          ease: 'power2.out',
        });

        const text = button.querySelector('span');
        if (text) {
          gsap.to(text, {
            x: 0,
            y: 0,
            duration: 0.5, // Faster duration
            ease: 'power2.out',
          });
        }
      };

      button.addEventListener('mousemove', handleMouseMoveButton);
      button.addEventListener('mouseleave', handleMouseLeaveButton);

      return () => {
        button.removeEventListener('mousemove', handleMouseMoveButton);
        button.removeEventListener('mouseleave', handleMouseLeaveButton);
      };
    });
  }, []);

  return (
    <section className="bg-black h-screen max-w-screen-2xl w-screen overflow-hidden">
      <div className="bg-black w-full max-w-screen-xl mx-auto px-0 text-white lg:py-28 py-6">
        <div className="flex mx-12 flex-col md:flex-row md:justify-start items-center">
          <div className="flex justify-center md:justify-start mb-6 md:mb-0">
            <img
              src="https://images.pexels.com/photos/24426275/pexels-photo-24426275/free-photo-of-young-blonde-woman-in-black-coat-sitting-and-smiling-in-sunlight.jpeg?auto=compress&cs=tinysrgb&w=400"
              alt="Profile"
              className="rounded-full lg:h-96 lg:w-60 md:w-52! h-64 transition-transform duration-500 ease-in-out hover:scale-95 cursor-pointer"
            />
          </div>

          <div className="flex flex-col md:mx-24 items-center md:items-start">
            <h1 className="lg:text-7xl sm:text-5xl text-3xl font-bold mb-4">Let’s Work Together</h1>
            <div className="w-full mt-6 flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 items-center">
              <div ref={buttonRefs.current[0]} className="inline-block">
                <button className="flex py-4 px-12 text-base items-center justify-center rounded-full bg-black border-white border-2 text-white transition-colors duration-500 ease-in-out hover:bg-white hover:text-black">
                  <span>business@digi-serve.com</span>
                </button>
              </div>
              <div ref={buttonRefs.current[1]} className="inline-block">
                <button className="flex py-4 px-12 text-base items-center justify-center rounded-full bg-black border-white border-2 text-white transition-colors duration-500 ease-in-out hover:bg-white hover:text-black">
                  <span>+1 (813) 934 4796 </span>
                </button>
              </div>
              {/* contact us Link */}
              <div ref={buttonRefs.current[2]} className="inline-block">
                <button className="flex sm:h-36 sm:w-36 h-28 w-28 text-base items-center justify-center rounded-full bg-white text-black transition-colors duration-500 ease-in-out hover:bg-white hover:text-black hover:border-white hover:border-2">
                  <span>Get in touch</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span className="block text-white text-sm py-3 text-center">
        © 2024 <a href="/" className="hover:underline">Digi-Serve™</a>. All Rights Reserved.
      </span>
    </section>
  );
}

export default Footer;
