import React, { useState, useEffect, useRef } from 'react';
import emailjs from 'emailjs-com';
import gsap from 'gsap';

const GridComponent = () => {
  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const popupRef = useRef(null);

  useEffect(() => {
    if (successMessage) {
      // GSAP animation for the popup
      gsap.fromTo(popupRef.current,
        { opacity: 0, y: -50 }, // Start state
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power3.out",
          onComplete: () => {
            setTimeout(() => {
              gsap.to(popupRef.current, {
                opacity: 0,
                y: -50,
                duration: 0.5,
                ease: "power3.in",
                onComplete: () => setSuccessMessage('') // Clear message
              });
            }, 3000); // Show for 3 seconds
          }
        }
      );
    }
  }, [successMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs.send('service_0o05sms', 'template_z69eyla', formData, 'BRIb1J9mJGwJAicX1') // Replace with your public key
      .then((response) => {
        setIsSubmitting(false);
        setSuccessMessage('Your message has been sent successfully!');
        setFormData({ email: '', subject: '', message: '' });
      })
      .catch((error) => {
        setIsSubmitting(false);
        setErrorMessage('Failed to send the message. Please try again later.');
      });
  };

  return (
    <>
      <section className="text-black pt-20">
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <h2 className="mb-4 text-4xl sm:text-9xl tracking-tight font-extrabold text-center text-black">
            Contact Us
          </h2>
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-900 sm:text-xl">
            Got a technical issue? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.
          </p>
          <form onSubmit={handleSubmit} className="space-y-8">
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                placeholder="name@flowbite.com"
                required
              />
            </div>
            <div>
              <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="block p-3 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                placeholder="Let us know how we can help you"
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">Your message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="6"
                className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                placeholder="Leave a comment..."
              ></textarea>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="py-3 px-5 bg-black text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300"
            >
              {isSubmitting ? 'Sending...' : 'Send message'}
            </button>
          </form>
          {successMessage && (
            <div
              ref={popupRef}
              className="fixed top-0 left-1/2 mt-24 transform -translate-x-1/2 bg-green-500 text-white p-4 rounded-2xl shadow-lg"
            >
              <p className="text-center">{successMessage}</p>
            </div>
          )}
          {errorMessage && <p className="text-red-500 text-center mt-4">{errorMessage}</p>}
        </div>
      </section>
    </>
  );
};

export default GridComponent;
