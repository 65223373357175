import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css'; // Ensure this file contains your scrollbar styles
import Footer from './Components/Footer';
import Hero from './Components/Hero';
import Logo from './Components/Logo/Logo';
import Testimonials from './Components/Testimonials';
import Slider from './Components/Slider';
import GridComponent from './Components/contactus';
import About from './Components/About';
import Navbar from './Components/Navbar';

function App() {
  // const [isLogoAnimationComplete, setIsLogoAnimationComplete] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  // const handleLogoAnimationComplete = () => {
  //   setIsLogoAnimationComplete(true);
  // };

  return (
    <Router>
            <Logo />

      <ScrollToTop /> {/* Ensure this component is included to handle scroll to top */}
      <Navbar />
      <Routes>
        <Route 
          exact path="/" 
          element={<CombinedComponents  />} 
        />
        <Route 
          exact path="/contact" 
          element={<CombinedContact />} 
        />
        <Route 
          exact path="/About" 
          element={<CombinedAbout />} 
        />
        <Route 
          path="*" 
          element={<Navigate to="/" replace />} 
        />
      </Routes>
    </Router>
  );
}

function CombinedComponents() {
  return (
    <div className="app-container">
    
        <>
          <Hero data-aos="fade-up" />
          <Slider data-aos="fade-up" />
          <Testimonials data-aos="fade-up" />
          <Footer data-aos="fade-up" />
        </>
    </div>
  );
}

function CombinedContact() {
  return (
    <div className="app-container">
      <>
        <GridComponent data-aos="fade-up" />
        <Footer data-aos="fade-up" />
      </>
    </div>
  );
}

function CombinedAbout() {
  return (
    <div className="app-container">
      <>
        <About data-aos="fade-up" />
        <Footer data-aos="fade-up" />
      </>
    </div>
  );
}

// Component to scroll to top on route change
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
