import React from 'react';
import TextEffect from './TextEffect';

const testimonials = [
  {
    text: "The team went above and beyond to ensure my issue was resolved quickly and efficiently. Truly outstanding!",
    name: "Jessica Devis",
    role: "Full Stack Developer @Netflix",
    img: "https://www.material-tailwind.com/image/netflix.svg",
  },
  {
    text: "It has broadened my horizons and helped me advance my career. The community is incredibly supportive.",
    name: "Marcell Glock",
    role: "Graphic Designer, @Coinbase",
    img: "https://www.material-tailwind.com/image/Logo-coinbase.svg",
  },
  {
    text: "Its intuitive design and powerful features make it indispensable. I can't imagine going back to life before it!",
    name: "Emma Roberts",
    role: "Chief Executive @Spotify",
    img: "https://www.material-tailwind.com/image/spotify.svg",
  }
];

const Testimonials = () => {
  return (
    <section className="sm:mx-8 mx-3 py-10 lg:py-28" data-aos="fade-up">
      <div className="container mx-auto">
        <div className="mx-auto flex max-w-4xl flex-col items-center text-center">
          <h2 className="mb-4 block font-sans text-5xl font-bold leading-[1.3] tracking-normal text-black antialiased lg:text-7xl">
            Testimonials
          </h2>
          <p className="text-black text-base mb-10 p-6 text-center">
            <TextEffect text="FROM LIFE-ENHANCING GADGETS TO UNPARALLELED CUSTOMER SUPPORT." fontSize="1rem" />
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 text-center">
          {testimonials.slice(0, 2).map((testimonial, index) => (
            <div key={index} className="relative flex flex-col rounded-2xl p-6 text-black">
              <div className="relative mx-4 mt-4 overflow-hidden rounded-xl bg-transparent bg-clip-border text-black shadow-none">
                <p className="text-blue-gray-900 mb-4 block font-sans text-2xl font-normal leading-relaxed antialiased lg:mb-20 text-center">
                  "{testimonial.text}"
                </p>
              </div>
              <div className="flex flex-wrap-reverse items-center justify-center gap-x-6 p-6 px-4 py-0">
                <div className="text-center">
                  <h6 className="text-blue-gray-900 block font-sans text-base font-semibold leading-relaxed tracking-normal antialiased">
                    {testimonial.name}
                  </h6>
                  <p className="block font-sans text-base font-normal leading-relaxed text-black text-inherit antialiased">
                    {testimonial.role}
                  </p>
                </div>
                <img src={testimonial.img} className="max-w-[8rem] mx-auto lg:ml-auto" alt={testimonial.name} />
              </div>
            </div>
          ))}
        </div>

        <div className="relative mt-8 flex flex-col rounded-2xl p-6 text-center text-black">
          <div className="relative mx-4 mt-4 overflow-hidden sm:text-center rounded-xl bg-transparent bg-clip-border text-black shadow-none">
            <p className="text-blue-gray-900 mx-auto mb-4 block max-w-4xl font-sans text-2xl font-normal leading-snug antialiased lg:text-3xl">
              "{testimonials[2].text}"
            </p>
          </div>
          <div className="mx-auto items-center p-6 py-2 text-center">
            <img src={testimonials[2].img} className="mx-auto max-w-[8rem] grayscale" alt="spotify" />
            <h6 className="text-black block font-sans text-base font-semibold leading-relaxed tracking-normal antialiased">
              {testimonials[2].name}
            </h6>
            <p className="block font-sans text-base font-normal leading-relaxed text-black text-inherit antialiased">
              {testimonials[2].role}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
