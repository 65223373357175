import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './Hero.css';
import AboutMe from './Aboutme'; // Ensure the path is correct
import { Link } from "react-router-dom";

const Hero = () => {
  const imageContainerRef = useRef(null);
  const imageRef = useRef(null);
  const textContainerRef = useRef(null);
  const titleRef = useRef(null);
  const title = useRef(null);
  const descriptionRef = useRef(null);
  const buttonRefs = useRef([]);

  useEffect(() => {
    const tl = gsap.timeline();

    // Animate the image container and image
    tl.fromTo(imageContainerRef.current,
      { x: '0%', backgroundColor: 'white', scale: 1, opacity: 0 },
      { x: '50%', backgroundColor: '#50C878', duration: 0.5, ease: 'bounce.in', opacity: 1 }
    )
    .fromTo(imageRef.current,
      { x: '100%', opacity: 0, scale: 1 },
      { x: '0%', opacity: 1, duration: 0.5, ease: 'power.out' },
      "=0" // Starts this animation 0.5 seconds before the previous one ends
    );

    // Animate the text container
    gsap.fromTo(
      titleRef.current,
      { x: '-100%', opacity: 0 },
      { x: '0%', opacity: 1, duration: 1, ease: 'power3.out' }
    );
    tl.fromTo(title.current,
      { x: '-100%', opacity: 0 },
      { x: '0%', opacity: 1, duration: 3, ease: 'power3.out' },
      "-=0.5" // Overlap with the previous animation by 0.5 seconds
    );
    tl.fromTo(textContainerRef.current,
      { y: '100%', opacity: 0 },
      { y: '0%', opacity: 1, duration: 1, ease: 'power3.out' },
      "-=2.5" // Overlap with the previous animation by 2.5 seconds
    );

    // Button effect logic
    const handleMouseMoveButton = (e) => {
      const button = e.currentTarget;
      const rect = button.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const moveX = (x - rect.width / 2) / 5;
      const moveY = (y - rect.height / 2) / 5;

      gsap.to(button, {
        x: moveX,
        y: moveY,
        duration: 0.5,
        ease: 'power2.out',
      });

      const text = button.querySelector('span');
      if (text) {
        gsap.to(text, {
          x: moveX,
          y: moveY,
          duration: 0.5,
          ease: 'power2.out',
        });
      }
    };

    const handleMouseLeaveButton = (e) => {
      const button = e.currentTarget;
      gsap.to(button, {
        x: 0,
        y: 0,
        duration: 0.5,
        ease: 'power2.out',
      });

      const text = button.querySelector('span');
      if (text) {
        gsap.to(text, {
          x: 0,
          y: 0,
          duration: 0.5,
          ease: 'power2.out',
        });
      }
    };

    // Add event listeners
    const buttons = buttonRefs.current;
    buttons.forEach(button => {
      if (button) { // Check if the button exists
        button.addEventListener('mousemove', handleMouseMoveButton);
        button.addEventListener('mouseleave', handleMouseLeaveButton);
      }
    });

    return () => {
      // Clean up event listeners
      buttons.forEach(button => {
        if (button) { // Check if the button exists
          button.removeEventListener('mousemove', handleMouseMoveButton);
          button.removeEventListener('mouseleave', handleMouseLeaveButton);
        }
      });
    };
  }, []);

  useEffect(() => {
    // GSAP animation for image hover effect
    const image = imageRef.current;

    if (image) { // Check if the image exists
      gsap.fromTo(image,
        { scale: 1, rotation: 0 },
        { scale: 1.05, rotation: 5, duration: 0.5, ease: 'power2.out', paused: true }
      ).eventCallback('onStart', () => {
        image.addEventListener('mouseover', () => {
          gsap.to(image, { scale: 1.1, rotation: 10, duration: 0.5, ease: 'power2.out' });
        });
        image.addEventListener('mouseleave', () => {
          gsap.to(image, { scale: 1, rotation: 0, duration: 0.5, ease: 'power2.out' });
        });
      });
    }
  }, []);

  return (
    <>
      <section className="height ok sm:overflow-hidden pt-0 lg:pl-8 lg:pt-0">
        <div className="overflow-hidden rounded-2xl">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div ref={textContainerRef} className='items-center sm:items-start'>
              <h1 ref={title} className="text-stroke shaa text-center sm:text-start pt-20 absolute text-6xl sm:text-9xl md:pt-32 xl:pt-20 md:text-8xl xl:text-8xl font-bold">
                Zepier Automation <br /> Expert
              </h1>
            </div>
            <div className="md:gap32 grid grid-cols-1 items-center gap-14 md:grid-cols-12">
              <div ref={textContainerRef} className="w-full  md:-mb-6 lg:mb-12 hmm md:col-span-8 lg:col-span-6 xl:col-span-7 text-start xl:-mx-0 2xl:-mx-5">
                <h1 ref={titleRef} className="font-manrope sha sm:mt-0 sm:mb-0 mb-10 mt-40 xl:py-5 xl:pt-14 text-6xl text-center md:text-5xl xl:text-6xl font-bold leading-[70px] text-black md:text-left">
                  The New Standard For Automation
                </h1>
                <p ref={descriptionRef} className="sm:py-5 sm:mb-0 mb-10 font-light text-center text-3xl lg:text-2xl xl:text-3xl text-black md:text-left">
                  When you’re ready to Automate, quickly execute your orders with Us.
                </p>
                <Link to="/contact">
                  <div ref={el => buttonRefs.current[0] = el} className="inline-block my-4 sm:mx-0 ml-9">
                    <button className="flex lg:py-3 lg:px-10 md:py-2 md:px-6 px-6 py-3 items-center justify-center rounded-full back border-black border-2 text-black transition-colors duration-500 ease-in-out hover:bg-black hover:text-white text-xs md:text-base">
                      <span>Contact Me</span>
                    </button>
                  </div>
                </Link>
                
                <div ref={el => buttonRefs.current[1] = el} className="inline-block sm:mx-5 ml-2 my-4">
                  <button
                    className="flex lg:py-3 lg:px-10 md:py-2 md:px-6 px-6 py-3 items-center justify-center rounded-full back border-black border-2 text-black transition-colors duration-500 ease-in-out hover:bg-black hover:text-white text-xs md:text-base"
                    onClick={() => window.location.href = 'https://calendly.com/business-digi-serve/30min'}
                  >
                    <span>Schedule a Meeting</span>
                  </button>
                </div>

              </div>

              <div ref={imageContainerRef} className="tilt width bg-black relative overflow-hidden">
                <img
                  ref={imageRef}
                  src="https://images.pexels.com/photos/23516208/pexels-photo-23516208/free-photo-of-woman-in-a-white-dress-looking-upwards.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="Profile"
                  className="width w-screen object-cover cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <AboutMe />
    </>
  );
};

export default Hero;
