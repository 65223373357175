import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './Slider.css';
import TextEffect from './TextEffect'; // Adjust the path if necessary

const Slider = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const imageContainerRef = useRef(null);
  const backgroundRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    const tl = gsap.timeline({
      onComplete: () => setSelectedImage(null)
    });

    tl.to([titleRef.current, descriptionRef.current], {
      y: (i) => i === 0 ? '100%' : '-100%',
      opacity: 0,
      duration: 0.5,
      ease: 'power2.inOut'
    });
    tl.to(imageContainerRef.current, {
      scale: 0.7,
      opacity: 0,
      duration: 0.5,
      ease: 'power2.inOut'
    });
    tl.to(backgroundRef.current, {
      opacity: 0,
      duration: 0.5,
      ease: 'power2.inOut'
    }, "<");
  };

  useEffect(() => {
    if (selectedImage) {
      const tl = gsap.timeline();

      tl.fromTo(backgroundRef.current, { opacity: 0 }, { opacity: 1, duration: 0.5 });
      tl.fromTo(imageContainerRef.current, { scale: 0.7, opacity: 0 }, { scale: 1, opacity: 1, duration: 0.5, ease: 'power2.out' });
      tl.fromTo(titleRef.current, { y: '100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.5, ease: 'power2.out' });
      tl.fromTo(descriptionRef.current, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 0.5, ease: 'power2.out' }, "<");
    }
  }, [selectedImage]);

  useEffect(() => {
    const cards = document.querySelectorAll('.image-card');
    cards.forEach(card => {
      const title = card.querySelector('h2');
      const image = card.querySelector('img');

      card.addEventListener('mouseenter', () => {
        const tl = gsap.timeline();
        tl.to(title, {
          y: -50,
          opacity: 0,
          duration: 0.2,
          ease: 'power1.inOut',
          onComplete: () => {
            gsap.set(title, { y: 50 });
            gsap.to(title, {
              y: 0,
              opacity: 1,
              duration: 0.4,
              ease: 'power1.inOut'
            });
          }
        });
        gsap.to(image, {
          scale: .95,
          duration: 0.2,
          ease: 'power1.inOut'
        });
      });

      card.addEventListener('mouseleave', () => {
        gsap.to(title, {
          y: 0,
          opacity: 1,
          duration: 0.2,
          ease: 'power1.inOut'
        });
        gsap.to(image, {
          scale: 1,
          duration: 0.2,
          ease: 'power1.inOut'
        });
      });
    });
  }, []);

  return (
    <div className="p-4 sm:mx-14 mx-6 bg-[#f1ede9]"  >
                <h2 className="lg:text-7xl text-5xl text-center font-bold tracking-wide mb-20"  >My Projects</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        <div className="bg-transparent overflow-hidden cursor-pointer image-card" onClick={() => handleImageClick('olalla')}>
          <h2 className="text-3xl font-normal tracking-wide mb-4">OLALLA</h2>
          <img src="https://images.pexels.com/photos/4946400/pexels-photo-4946400.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Card 1" className="w-full h-96 object-cover mb-4 rounded-3xl " />
          <TextEffect text="YOU COULD BE MY UNINTENDED CHOICE TO LIVE MY LIFE EXTENDED YOU COULD BE THE ONE I'LL ALWAYS LOVE" fontSize="0.8rem"  />
        </div>

        <div className="bg-transparent overflow-hidden pt-24 cursor-pointer image-card" onClick={() => handleImageClick('elBuho')}>
          <h2 className="text-3xl font-normal tracking-wide mb-4">EL BÚHO</h2>
          <img src="https://images.pexels.com/photos/4906283/pexels-photo-4906283.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Card 2" className="w-full h-96 object-cover mb-4 rounded-3xl " />
          <TextEffect text="YOU COULD BE THE ONE WHO LISTENS TO MY DEEPEST INQUISITIONS YOU COULD BE THE ONE I'LL ALWAYS LOVE" fontSize="0.8rem"  />
        </div>

        <div className="bg-transparent overflow-hidden cursor-pointer image-card" onClick={() => handleImageClick('nocturne')}>
          <h2 className="text-3xl font-normal tracking-wide mb-4">NOCTURNE</h2>
          <img src="https://images.pexels.com/photos/11824539/pexels-photo-11824539.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Card 3" className="w-full h-96 object-cover mb-4 rounded-3xl " />
          <TextEffect text="I'LL BE THERE AS SOON AS I CAN BUT I'M BUSY MENDING BROKEN PIECES OF THE LIFE I HAD BEFORE" fontSize="0.8rem"  />
        </div>

        <div className="bg-transparent overflow-hidden pt-24 cursor-pointer image-card" onClick={() => handleImageClick('outsider')}>
          <h2 className="text-3xl font-normal tracking-wide mb-4">OUTSIDER</h2>
          <img src="https://images.pexels.com/photos/27310377/pexels-photo-27310377/free-photo-of-portrait-of-brunette-woman-in-shadow-in-black-and-white.jpeg?auto=compress&cs=tinysrgb&w=400" alt="Card 4" className="w-full h-96 object-cover mb-4 rounded-3xl " />
          <TextEffect text="FIRST THERE WAS THE ONE WHO CHALLENGED ALL MY DREAMS AND ALL MY BALANCE SHE COULD NEVER BE AS GOOD AS YOU" fontSize="0.8rem"  />
        </div>
      </div>

      {selectedImage && (
        <div className="fixed inset-0 bg-black flex items-center justify-center z-50 transition-opacity duration-500" ref={backgroundRef} onClick={handleClose}>
          <div className="relative max-w-4xl w-full text-center image-container-wrapper">
            <div className="image-container" ref={imageContainerRef}>
              {selectedImage === 'olalla' && (
                <>
                  <img src="https://images.pexels.com/photos/4946400/pexels-photo-4946400.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Card 1" className="selected-image" />
                  <h2 className="overlay-text" ref={titleRef}>OLALLA</h2>
                  <p className="description-text" ref={descriptionRef}>YOU COULD BE MY UNINTENDED CHOICE TO LIVE MY LIFE EXTENDED YOU COULD BE THE ONE I'LL ALWAYS LOVE</p>
                </>
              )}
              {selectedImage === 'elBuho' && (
                <>
                  <img src="https://images.pexels.com/photos/4906283/pexels-photo-4906283.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Card 2" className="selected-image" />
                  <h2 className="overlay-text" ref={titleRef}>EL BÚHO</h2>
                  <p className="description-text DIFF" ref={descriptionRef}>YOU COULD BE THE ONE WHO LISTENS TO MY DEEPEST INQUISITIONS YOU COULD BE THE ONE I'LL ALWAYS LOVE" </p>
                </>
              )}
              {selectedImage === 'nocturne' && (
                <>
                  <img src="https://images.pexels.com/photos/11824539/pexels-photo-11824539.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Card 3" className="selected-image" />
                  <h2 className="overlay-text" ref={titleRef}>NOCTURNE</h2>
                  <p className="description-text" ref={descriptionRef}>FIRST THERE WAS THE ONE WHO CHALLENGED ALL MY DREAMS AND ALL MY BALANCE SHE COULD NEVER BE AS GOOD AS YOU</p>
                </>
              )}
              {selectedImage === 'outsider' && (
                <>
                  <img src="https://images.pexels.com/photos/27310377/pexels-photo-27310377/free-photo-of-portrait-of-brunette-woman-in-shadow-in-black-and-white.jpeg?auto=compress&cs=tinysrgb&w=400" alt="Card 4" className="selected-image " />
                  <h2 className="overlay-text" ref={titleRef}>OUTSIDER</h2>
                  <p className="description-text" ref={descriptionRef}>FIRST THERE WAS THE ONE WHO CHALLENGED ALL MY DREAMS AND ALL MY BALANCE SHE COULD NEVER BE AS GOOD AS YOU</p>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Slider;
