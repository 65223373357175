import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import styles from './TextEffect.module.css'; // Adjust the path if necessary

const TextEffect = ({ text, fontSize }) => {
  const textRef = useRef(null);

  useEffect(() => {
    const textElem = textRef.current;
    const chars = textElem.textContent.split('');

    textElem.innerHTML = chars.map(char => `<span>${char}</span>`).join('');

    const spans = textElem.querySelectorAll('span');

    spans.forEach((span) => {
      span.addEventListener('mouseenter', () => {
        gsap.to(span, { scale: 1.7, margin: '0 0.1rem', duration: 0.5, ease: "power3.out", zIndex: 2});
      });

      span.addEventListener('mouseleave', () => {
        gsap.to(span, { scale: 1.3, margin: '0 0.1rem', duration: 0.5, ease: "power3.inOut", boxShadow: 'none' });
      });
    });
  }, []);

  return (
    <p className={styles.hoverText} ref={textRef} style={{ fontSize }}>
      {text}
    </p>
  );
};

export default TextEffect;
